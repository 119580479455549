import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import ru from './translates/ru.json';
import en from './translates/en.json';

const urlPath = window.location.pathname;
const pathSegments = urlPath.includes('/ru');
localStorage.setItem('language', pathSegments ? 'ru' : 'en');
const lang = localStorage.getItem('language') || 'en';
if (urlPath === '/en') window.location.href = '/'

console.log(lang)

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en
            },
            ru: {
                translation: ru
            },
        },
        fallbackLng: lang,
        debug: true,
    });

export default i18n;